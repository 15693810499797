import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FooterHome from "./FooterHome";

const Home = props => {
    return (

        <>
        <div role="main">

            <div className="jumbotron">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-7">
                            <h1><b>DclGenerator</b></h1>
                            <p>Don't waste more your time! Dclgenerator is a tool for letting you code easier. </p>
                        </div>
                        <div className="text-center col-sm-5 p-3">
                            <span className="pr-3" ><FontAwesomeIcon className="fa fa-4x" icon="file" /></span>
                            <span className="pr-3" ><FontAwesomeIcon className="fa fa-3x" icon="angle-double-right" /></span>
                            <span className="pr-3" ><FontAwesomeIcon className="fa fa-4x" icon="database" /></span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">

                You need to code some DB2 CRUD in a faster way?
                <p>Just load your dclgen, choose your fields and let the tool get the job done. </p>
                <p><a className="btn btn-primary" href="/dclgen" role="button">Try it now &raquo;</a></p>

            </div>
        </div>

        <FooterHome />
        </>

    )
}

export default Home