import React, { Component } from "react";

export default class Policy extends Component {
  render() {
    return (
      <div className="mt-5 mb-5 ml-5 mr-5">
        <h1>Políticas</h1>
        <p>
          A privacidade dos visitantes do Dclgenerator.com é muito importante para
          nós e estamos empenhados em assegurá-la. Essa política e termos
          explicam o que fazemos com suas informações pessoais.
        </p>
        <p>
          Ao usar o Dclgenerator.com, você consente a coleção e uso de dados
          pessoais descritos abaixo.
        </p>
        <h2>Coleta de informações</h2>
        <p>
          As seguintes informações pessoais podem ser coletadas, armazenadas e
          usadas:
        </p>
        <ul>
          {" "}
          <li>
            Informações sobre o computador incluindo endereço IP, localização
            geográfica, tipo de navegador e versão, duração de visita e sistema
            operacional.
          </li>{" "}
          <li>
            Informações sobre sua visita e uso do website incluindo fonte de
            referência, duração da visita, visualizações de página e caminhos de
            navegação no website.
          </li>{" "}
          <li>
            Informações como email, nome e demais informações enviadas ao entrar
            em contato conosco via email incluindo conteúdo e metadados.
          </li>{" "}
          <li>
            Informações geradas enquanto usa o website incluindo onde, com que
            frequência e em que circunstâncias.
          </li>{" "}
          <li>Qualquer outra informação pessoal enviada para nós.</li>
        </ul>
        <h2>Política de privacidade</h2>
        <p>
          Dclgenerator.com pode utilizar cookies e/ou web beacons quando um usuário
          tem acesso às páginas. Os cookies que podem ser utilizados associam-se
          (se for o caso) unicamente com o navegador de um determinado
          computador.
        </p>
        <p>
          Cookies são pequenos arquivos de texto colocados em sua máquina para
          ajudar o website a prover uma melhor experiência do usuário. Em geral,
          cookies armazenam preferências do usuário, armazenam informação de
          coisas como carrinhos de compras e provêem dados do usuári
          anonimamente à aplicações de terceiros como o Google Analytics. Por
          via de regra, cookies melhoram a experiência de navegação. No entanto,
          você pode preferir desabilitar os cookies desse website ou de outros.
          A maneira mais eficaz de fazer isso é desabilitando cookies no seu
          navegador. Sugerimos você procurar a seção Ajuda do seu navegador para
          isso.
        </p>
        <p>
          Os cookies que são utilizados no Dclgenerator.com podem ser instalados
          pelo mesmo, os quais são originados dos distintos servidores operados
          por este, ou a partir dos servidores de terceiros que prestam serviços
          e instalam cookies e/ou web beacons (por exemplo, os cookies que são
          empregados para prover serviços de publicidade ou certos conteúdos
          através dos quais o usuário visualiza a publicidade ou conteúdos em
          tempo pré determinados). O usuário poderá pesquisar o disco rígido de
          seu computador conforme instruções do próprio navegador.
        </p>
        <p>
          Usuário tem a possibilidade de configurar seu navegador para ser
          avisado, na tela do computador, sobre a recepção dos cookies e para
          impedir a sua instalação no disco rígido. As informações pertinentes a
          esta configuração estão disponíveis em instruções e manuais do próprio
          navegador.
        </p>
        <p>
          Usamos empresas de publicidade de terceiros para veicular anúncios
          durante a sua visita ao Dclgenerator.com. Essas empresas podem usar
          informações (que não incluem o seu nome, endereço, endereço de e-mail
          ou número de telefone) sobre suas visitas a este e a outros websites a
          fim de exibir anúncios relacionados a produtos e serviços de seu
          interesse. Para obter mais informações sobre essa prática e saber como
          impedir que as empresas utilizem esses dados,{" "}
          <a
            href="https://policies.google.com/technologies/ads?hl=pt-BR"
            rel="nofollow"
          >
            clique aqui
          </a>{" "}
          e{" "}
          <a
            href="https://policies.google.com/technologies/partner-sites"
            rel="nofollow"
          >
            aqui
          </a>
          .
        </p>
        <h2>
          Dados pessoais coletados para os seguintes propósitos e usando os
          seguintes serviços
        </h2>
        <p>
          Estatística: Google Analytics com IP anônimo. Dados pessoais: cookies
          e dados de uso. Você pode optar por desativar o Google Analytics com
          seu{" "}
          <a
            href="https://tools.google.com/dlpage/gaoptout?hl=pt-br"
            rel="nofolllow noopener noreferrer"
            target="_blank"
          >
            addon
          </a>
          .
        </p>
        <p>
          Anúncios: Google AdSense e Criteo. Dados pessoais: cookies e dados de
          uso. Você pode desativar a personalização de anúncios do Google
          através das{" "}
          <a
            href="https://www.google.com/settings/ads"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            configurações de anúncios
          </a>
          . Leia também a{" "}
          <a
            href="https://www.criteo.com/br/privacy/"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            política de privacidade da Criteo
          </a>
          .
        </p>
        <p>
          Hospedagem e infraestrutura do backend: Digital Ocean. Dados pessoais:
          os especificados na política de privacidade do serviço.
        </p>
        <p>
          Boletim via email: Google Feedburner. Dados pessoais: Email. Somente
          receberão mensagens os usuários que se cadastrarem no programa e
          optarem pelo recebimento de novidades do site após confirmação. É
          possível descadastrar através de um link no email do boletim, assim
          não caracterizando como SPAM.
        </p>
        <p>
          Comunicação: Email. Se você se identificar nos enviando um email
          contendo informações pessoais, então a informação coletada somente
          será usada para que possamos responder à mensagem.
        </p>
        <p>
          Segurança: Para propósitos de segurança e garantir que este serviço
          esteja disponível para todos os seus usuários, Dclgenerator.com usa
          softwares de monitoramento de tráfego da rede para tentar identificar
          tentativas de acessos não autorizadas ou que possam causar danos.
        </p>
        <h2>Mais informações sobre dados pessoais</h2>
        <p>
          Nós não vendemos ou alugamos dados dos usuários a terceiros para
          qualquer propósito. Os únicos usos de dados estão mencionados nessa
          política. O usuário é dono de seus dados e pode optar por não
          compartilhá-los desabilitando os cookies de seu navegador ou pelos
          links mencionados de desativação de Analytics e anúncios.
        </p>
        <h2>Links para websites de terceiros ou recursos</h2>
        <p>
          Dclgenerator.com contém links para outros websites. Se você usar links
          para acessar outros sites, seu navegador será redirecionados para
          outros sites que podem ter seus próprios termos de uso e política de
          privacidade. Dclgenerator.com não é responsável pelo conteúdo,
          informação, serviços, produtos e anúncios apresentados em outros
          websites. Você deve acessá-los por seu próprio risco. Os links não
          constituem apoio pelo Dclgenerator.com de outros websites ou recursos,
          conteúdo, informação, serviços e produtos. Dclgenerator.com somente
          oferece esses links por conveniência. Dclgenerator.com não aceita
          responsabilidade pelo conteúdo ou conduta de outros websites.
        </p>
        <h2>Armazenamento de dados</h2>
        <p>
          Dclgenerator.com usa fornecedores de terceiros e parceiros de hospedagem
          para prover o hardware, software, rede, armazenamento e tecnologias
          relacionadas necessárias para manter os serviços de Dclgenerator.com.
          Dclgenerator.com é dona do código, banco de dados e todos os direitos das
          aplicações do Dclgenerator.com.
        </p>
        <h2>Segurança de informação</h2>
        <p>
          Nós tomamos precauções para garantir a segurança das suas informações
          pessoais. No entanto, não podemos garantir que hackers ou pessoas não
          autorizadas consigam acesso à suas informações pessoais mesmo com
          nossos esforços. Você deve notar que ao usar os serviços do
          Dclgenerator.com, suas informações irão passar por infraestrutura de
          terceiros que não estão no nosso controle.
        </p>
        <p>
          Nós não podemos proteger, nem essa política de privacidade pode se
          aplicar para, qualquer informação que você transmita para nossos
          usuários. Você nunca deve transmitir informações pessoais ou que
          possam identificá-lo aos outros usuários.
        </p>
        <h2>Compartilhamento de informação perante à lei</h2>
        <p>
          É necessário o compartilhamento de informação para que haja
          investigação, prevenção ou tomada de ação em relação à atividades
          ilegais, suspeita de fraude, situações envolvendo possíveis ameaças à
          segurança física de qualquer pessoa, violações dos termos de serviço e
          política ou outra requerida pela lei.
        </p>
        <h2>Transferência de dados entre países</h2>
        <p>
          Informações coletadas podem ser armazenadas, processadas e
          transferidas entre os países em que operamos para que nos permita o
          uso de informação de acordo com a política e termos.
        </p>
        <p>
          Os dados pessoais que você publicar em nosso website ou enviados para
          publicação em nosso website podem estar disponíveis, via Internet, ao
          redor do mundo. Não podemos prevenir o uso ou mau uso dessas
          informações por outros.
        </p>
        <h2>
          Sobre as extensões do Google Chrome e Mozilla FireFox do Dclgenerator.com
        </h2>
        <p>
          Não coletamos informações pessoais através das extensões. O Google e a
          Mozilla, no entanto, exibem o nome do usuário e perfil público em seus
          serviços quando o usuário das extensões do Dclgenerator.com enviam
          comentário, análise ou requisições de suporte. Esses dados serão
          usados somente para melhoria dos nossos serviços e contato com o
          usuário sobre seus comentários. Dados estatísticos agregados (não
          identificáveis) de usos das extensões podem ser enviados ao
          Dclgenerator.com pelas empresas mencionadas ou serem exibidos
          publicamente em seus respectivos sites. Instalando as extensões do
          Dclgenerator.com você está sujeito aos termos e políticas das empresas
          criadoras dos navegadores e plataformas de extensões.
        </p>
        <h2>Isenção de responsabilidade na conversão de moeda</h2>
        <p>
          O Dclgenerator.com não garante a precisão das taxas de câmbio usadas pela
          calculadora. Confirme as taxas atuais antes de efetuar transações que
          possam ser afetadas pelas alterações nas taxas de câmbio. As taxas têm
          apenas fins informativos e estão sujeitas a alterações sem notificação
          prévia. As taxas para transações reais podem variar e o Dclgenerator.com
          não está se oferecendo para participar de nenhuma transação a qualquer
          taxa exibida.
        </p>
        <h2>Alterações</h2>
        <p>
          Poderemos atualizar esta política de privacidade e termos de tempos em
          tempos, portanto você deverá verificá-la periodicamente. Se alterações
          substanciais forem feitas, notificaremos você sobre tais alterações
          através de uma nota proeminente no site.
        </p>
        <h2>Informação de contato</h2>
        <p>Dono e controlador de dados: Rodrigo Santos</p>
        <p>Email de contato: ro.augusto@gmail.com</p>
      </div>
    );
  }
}
