import React, { Component } from "react";
import AdSense from "react-adsense";

import ColFirst from "../components/SqlGen/ColFirst";
import ColSecond from "../components/SqlGen/ColSecond";
import Footer from "./Footer";

export default class SqlGenerator extends Component {
  render() {
    return (
      <>
        <div className="mt-2">
          <div className="mb-2" style={{ display: 'flex', justifyContent: 'center'}}>
            <AdSense.Google
              
              client="ca-pub-3002259314332128"
              slot="6936396441"
              style={{ width: 728, height: 90, float: "center" }}
              format=""
            />
          </div>

          <div role="main">
            <div className="row ml-1 mr-2 ">
              <ColFirst />
              <ColSecond />
            </div>
          </div>
          <div className="ml-3 mr-4 mt-2 mb-4">
            <div className="card">
              <div className="card-body">
                <h2 className="card-title">What is DCLGEN?</h2>
                <p className="card-text">
                  DCLGEN (Declaration Generator) is a structure‑generating
                  utility that maps the columns of a database table into a
                  structure (a <a href="#cobol">COBOL</a> record) that can be
                  included in an embedded SQL declaration section
                  <br />
                  <br />
                  Ex:
                </p>
                <div
                  style={{
                    fontFamily:
                      "Courier New,Courier,Lucida Sans Typewriter,Lucida Typewriter,monospace",
                  }}
                >
                  <pre>
                    {"    "}
                    ******************************************************************
                    <br />
                    {"    "}* DCLGEN TABLE(CONTRACT) *<br />
                    {"    "}* LIBRARY(AD.DB2.DCLGEN(CONTB001)) *<br />
                    {"    "}* ACTION(REPLACE) *<br />
                    {"    "}* LANGUAGE(COBOL) *<br />
                    {"    "}* STRUCTURE(CONTB001) *<br />
                    {"    "}* APOST *<br />
                    {"    "}* LABEL(YES) *<br />
                    {"    "}* ... IS THE DCLGEN COMMAND THAT MADE THE FOLLOWING
                    STATEMENTS *<br />
                    {"    "}
                    ******************************************************************
                    <br />
                    {"         "}
                    EXEC SQL DECLARE CONTRACT TABLE <br />
                    {"         "}( NUM_DOC DECIMAL(10, 0) NOT NULL, <br />
                    {"           "}
                    TP_CONTRACT DECIMAL(7, 0) NOT NULL, <br />
                    {"           "}
                    SEQ_CONTRACT DECIMAL(17, 0) NOT NULL, <br />
                    {"           "}
                    STATUS_CONTRACT DECIMAL(3, 0) NOT NULL, <br />
                    {"           "}
                    COD_LANGUAGE DECIMAL(3, 0) NOT NULL, <br />
                    {"           "}
                    COD_PRODUCT DECIMAL(8, 0) NOT NULL, <br />
                    {"           "}
                    DATETIME_CONTRACT TIMESTAMP NOT NULL, <br />
                    {"           "}
                    PERC_LIM DECIMAL(9, 7), <br />
                    {"           "}
                    VAL_CONTRACT DECIMAL(15, 2), <br />
                    {"           "}
                    CUSER_INS CHAR(9) NOT NULL, <br />
                    {"           "}
                    HINSERT_REG TIMESTAMP, <br />
                    {"           "}
                    CUSER_UPD CHAR(9), <br />
                    {"           "}
                    HUPD_REG TIMESTAMP <br />
                    {"         "}) END-EXEC. <br />
                    {"    "}
                    ******************************************************************
                    <br />
                    {"    "}* COBOL DECLARATION FOR TABLE CONTRACT *<br />
                    {"    "}
                    ******************************************************************
                    <br />
                    {"    "} 01 CONTB001. <br />
                    {"    "}*
                    *************************************************************
                    <br />
                    {"    "} 10 NUM-DOC PIC S9(10)V USAGE COMP-3. <br />
                    {"    "}*
                    *************************************************************
                    <br />
                    {"    "} 10 TP-CONTRACT PIC S9(7)V USAGE COMP-3. <br />
                    {"    "}*
                    *************************************************************
                    <br />
                    {"    "} 10 SEQ-CONTRACT PIC S9(17)V USAGE COMP-3. <br />
                    {"    "}*
                    *************************************************************
                    <br />
                    {"    "} 10 STATUS-CONTRACT PIC S9(3)V USAGE COMP-3. <br />
                    {"    "}*
                    *************************************************************
                    <br />
                    {"    "} 10 COD-LANGUAGE PIC S9(3)V USAGE COMP-3. <br />
                    {"    "}*
                    *************************************************************
                    <br />
                    {"    "} 10 COD-PRODUCT PIC S9(8)V USAGE COMP-3. <br />
                    {"    "}*
                    *************************************************************
                    <br />
                    {"    "} 10 DATETIME-CONTRACT PIC X(26). <br />
                    {"    "}*
                    *************************************************************
                    <br />
                    {"    "} 10 PERC-LIM PIC S9(2)V9(7) USAGE COMP-3. <br />
                    {"    "}*
                    *************************************************************
                    <br />
                    {"    "} 10 VAL-CONTRACT PIC S9(13)V9(2) USAGE COMP-3.{" "}
                    <br />
                    {"    "}*
                    *************************************************************
                    <br />
                    {"    "} 10 CUSER-INS PIC X(9). <br />
                    {"    "}*
                    *************************************************************
                    <br />
                    {"    "} 10 HINSERT-REG PIC X(26). <br />
                    {"    "}*
                    *************************************************************
                    <br />
                    {"    "} 10 CUSER-UPD PIC X(9). <br />
                    {"    "}*
                    *************************************************************
                    <br />
                    {"    "} 10 HUPD-REG PIC X(26). <br />
                    {"    "}
                    ******************************************************************
                    <br />
                  </pre>
                </div>
              </div>
            </div>
          </div>
          <div className="ml-3 mr-4 mt-2 mb-5">
            <div id="cobol" className="card">
              <div className="card-body">
                <h2 className="card-title">What is COBOL?</h2>
                <div className="ml-3 mr-3">
                  <p className="card-text" style={{ textAlign: "justify" }}>
                    COBOL (/ˈkoʊbɒl, -bɔːl/; an acronym for "common
                    business-oriented language") is a compiled English-like
                    computer programming language designed for business use. It
                    is an imperative, procedural and, since 2002,
                    object-oriented language. COBOL is primarily used in
                    business, finance, and administrative systems for companies
                    and governments. COBOL is still widely used in applications
                    deployed on mainframe computers, such as large-scale batch
                    and transaction processing jobs. However, due to its
                    declining popularity and the retirement of experienced COBOL
                    programmers, programs are being migrated to new platforms,
                    rewritten in modern languages or replaced with software
                    packages.[8] Most programming in COBOL is now purely to
                    maintain existing applications; however, many large
                    financial institutions were still developing new systems in
                    COBOL as late as 2006 due to the mainframe processing speed.
                    <br />
                    <br />
                    COBOL was designed in 1959 by CODASYL and was partly based
                    on the programming language FLOW-MATIC designed by Grace
                    Hopper. It was created as part of a US Department of Defense
                    effort to create a portable programming language for data
                    processing. It was originally seen as a stopgap, but the
                    Department of Defense promptly forced computer manufacturers
                    to provide it, resulting in its widespread adoption.
                    <br />
                    <br />
                    It was standardized in 1968 and has since been revised four
                    times. Expansions include support for structured and
                    object-oriented programming. The current standard is ISO/IEC
                    1989:2014.
                    <br />
                    <br />
                    COBOL statements have an English-like syntax, which was
                    designed to be self-documenting and highly readable.
                    However, it is verbose and uses over 300 reserved words. In
                    contrast with modern, succinct syntax like y = x;, COBOL has
                    a more English-like syntax (in this case, MOVE x TO y).
                    COBOL code is split into four divisions (identification,
                    environment, data, and procedure) containing a rigid
                    hierarchy of sections, paragraphs and sentences. Lacking a
                    large standard library, the standard specifies 43
                    statements, 87 functions and just one class.
                    <br />
                    <br />
                    Academic computer scientists were generally uninterested in
                    business applications when COBOL was created and were not
                    involved in its design; it was (effectively) designed from
                    the ground up as a computer language for business, with an
                    emphasis on inputs and outputs, whose only data types were
                    numbers and strings of text.[12] COBOL has been criticized
                    throughout its life for its verbosity, design process, and
                    poor support for structured programming. These weaknesses
                    result in monolithic and, though intended to be
                    English-like, not easily comprehensible and verbose
                    programs.
                  </p>
                </div>
                <br />
                Source:{" "}
                <a
                  href="https://en.wikipedia.org/wiki/COBOL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://en.wikipedia.org/wiki/COBOL
                </a>
              </div>
            </div>
          </div>

          <div className="mr-4 ml-4 mb-5" style={{ display: 'flex', justifyContent: 'center'}}>
          <AdSense.Google
            client="ca-pub-3002259314332128"
            slot="6928851109"
            style={{ display: "block", height: 180 }}
            format=""
          />
        </div>

        </div>

        <Footer />
      </>
    );
  }
}
