import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'mobx-react'

import './index.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css'

import App from './main/App';

import * as serviceWorker from './serviceWorker';

import TableStore from './stores/TableStore'

const stores = {
    TableStore
}

ReactDOM.render(
    <Provider {...stores}>
        <App />
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
