import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import './App.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'

import Home from '../components/Home'
import SqlGenerator from '../components/SqlGenerator'
import Policy from '../components/Policy'
import Header from '../components/Header'

library.add(fas, faCheckSquare, faCoffee)

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <Header />
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/dclgen' exact component={SqlGenerator} />
            <Route path='/policy' exact component={Policy} />
          </Switch>
          
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
