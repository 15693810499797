import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Header = props => {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark py-0">
            <a className="navbar-brand mr-auto mr-lg-0" href="/"><FontAwesomeIcon icon="bolt" /> SQL Generator for DB2</a>

            <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                    <a className="nav nav-link" target="_blank" rel="noopener noreferrer"
                        href='https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=N39LKJ8M7CGL8&currency_code=USD&source=url'>                        
                        <img src='https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif' alt="paypal" />
                    </a>
                </li>
            </ul>
        </nav>


    )
}

export default Header