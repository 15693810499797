import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Tooltip } from 'reactstrap';

import GenCode from './GenCode'

@inject('TableStore')
@observer
class ColSecond extends Component {

    state = {
        collapsedVar: true,
        collapsedCode: true,
        code: '',
        ifNull: true,
        message: 'Copy to clipboard!',
        tooltipOpen1: false,
        tooltipOpen2: false
    };

    toggleCollapse = () => {
        this.setState({
            collapsedVar: !this.state.collapsedVar,
            collapsedCode: !this.state.collapsedCode
        });
    }

    handleSelect = () => {

        const { fieldsStore } = this.props.TableStore
        const { whereStore } = this.props.TableStore

        let code = GenCode.handleSelect(fieldsStore, whereStore)
        let working = GenCode.handleWorking(fieldsStore, false)

        this.props.TableStore.code = code
        this.props.TableStore.working = working

    }

    handleInsert = () => {

        const { fieldsStore } = this.props.TableStore

        let code = GenCode.handleInsert(fieldsStore)
        let working = GenCode.handleWorking(fieldsStore, true)

        this.props.TableStore.code = code
        this.props.TableStore.working = working

    }

    handleUpdate = () => {

        const { fieldsStore } = this.props.TableStore
        const { whereStore } = this.props.TableStore

        let code = GenCode.handleUpdate(fieldsStore, whereStore)
        let working = GenCode.handleWorking(fieldsStore, true)

        this.props.TableStore.code = code
        this.props.TableStore.working = working

    }

    handleDelete = () => {

        const { fieldsStore } = this.props.TableStore
        const { whereStore } = this.props.TableStore

        let code = GenCode.handleDelete(whereStore)
        let working = GenCode.handleWorking(fieldsStore, true)

        this.props.TableStore.code = code
        this.props.TableStore.working = working

    }


    handleCursor = () => {

        const { fieldsStore } = this.props.TableStore
        const { whereStore } = this.props.TableStore

        let working = GenCode.handleWorkingCursor(fieldsStore, whereStore, false)
        let code = GenCode.handleCursor(fieldsStore, whereStore)

        this.props.TableStore.code = code
        this.props.TableStore.working = working

    }


    toggleIfNull = () => {
        this.setState({ ifNull: !this.state.ifNull })
        GenCode.setIfNull(this.state.ifNull)
    }

    handleCode = () => { }
    handleWorking = () => { }

    onCopy = () => {
        this.setState({ message: 'Copied!' });
    }

    setMessage = () => {
        this.setState({ message: 'Copy to clipboard!' });
    }

    toggleToolTip1 = () => {
        this.setState({ tooltipOpen1: !this.state.tooltipOpen1 });
    }

    toggleToolTip2 = () => {
        this.setState({ tooltipOpen2: !this.state.tooltipOpen2 });
    }

    render() {
        const collapsedVar = this.state.collapsedVar;
        const collapsedCode = this.state.collapsedCode;
        const classVar = collapsedVar ? 'collapse' : 'collapse show';
        const classCode = collapsedCode ? 'collapse show' : 'collapse';

        return (
            <div className="col-sm-8 ">

                <div className="row align-middle">

                    <div className="col-sm-8">
                        <div className="ml-2 mb-2">
                            <button type="button" onClick={this.handleSelect} className="btn btn-sm btn-secondary m-1">Select</button>
                            <button type="button" onClick={this.handleInsert} className="btn btn-sm btn-secondary m-1">Insert</button>
                            <button type="button" onClick={this.handleUpdate} className="btn btn-sm btn-secondary m-1">Update</button>
                            <button type="button" onClick={this.handleDelete} className="btn btn-sm btn-secondary m-1">Delete</button>
                            <button type="button" onClick={this.handleCursor} className="btn btn-sm btn-secondary m-1">Cursor</button>
                        </div>
                    </div>

                    <div className="col-sm-3 ml-4">
                        <div className="mt-2">
                            <input type="checkbox"
                                onChange={() => this.toggleIfNull()}
                                className="form-check-input" />
                            <label className="text">Use IFNULL</label>
                        </div>
                    </div>


                </div>

                <div className="accordion" id="codeGroup">

                    <div className="card">
                        <div className="card-header p-0" id="variables">
                            <button onClick={this.toggleCollapse} className="btn btn-link"
                                type="button"
                                data-toggle="collapse" data-target="#collapseVariables"
                                aria-expanded="true" aria-controls="collapseVariables">
                                Working Variables
                            </button>
                            <CopyToClipboard text={this.props.TableStore.working}>
                                <span>
                                    <label className="btn btn-sm btn float-right" id={'tooltip1'}
                                        onClick={this.onCopy}
                                        onMouseEnter={this.setMessage}>
                                        <FontAwesomeIcon icon="copy" />
                                    </label>
                                    <Tooltip placement={'left'}
                                        isOpen={this.state.tooltipOpen1}
                                        target={'tooltip1'}
                                        toggle={this.toggleToolTip1}
                                    >{this.state.message}
                                    </Tooltip>
                                </span>
                            </CopyToClipboard>
                        </div>

                        <div id="collapseVariables" className={`${classVar}`} aria-labelledby="variables" data-parent="#codeGroup">
                            <div className="card-body" style={{ height: "70vh" }}>
                                <textarea value={this.props.TableStore.working}
                                    className="form-control"
                                    onChange={this.handleWorking}
                                    style={{ height: "100%", fontSize: "12px", resize: "none", fontFamily: "Courier New" }}></textarea>

                            </div>
                        </div>
                    </div>

                    <div className="card mb-2">
                        <div className="card-header p-0" id="code">
                            <h5 className="mb-0">
                                <button onClick={this.toggleCollapse} className="btn btn-link"
                                    type="button" data-toggle="collapse" data-target="#collapseCode"
                                    aria-expanded="false" aria-controls="collapseCode">
                                    Code
                                </button>
                                <CopyToClipboard text={this.props.TableStore.code}>

                                    <span>
                                        <label className="btn btn-sm btn float-right" id={'tooltip2'}
                                            onClick={this.onCopy}
                                            onMouseEnter={this.setMessage}>
                                            <FontAwesomeIcon icon="copy" />
                                        </label>
                                        <Tooltip placement={'left'}
                                            isOpen={this.state.tooltipOpen2}
                                            target={'tooltip2'}
                                            toggle={this.toggleToolTip2}
                                        >{this.state.message}
                                        </Tooltip>
                                    </span>

                                </CopyToClipboard>
                            </h5>
                        </div>
                        <div id="collapseCode" className={`${classCode}`} aria-labelledby="code" data-parent="#codeGroup">
                            <div className="card-body" style={{ height: "70vh" }}>
                                <textarea value={this.props.TableStore.code}
                                    className="form-control"
                                    onChange={this.handleCode}
                                    style={{ height: "100%", fontSize: "12px", resize: "none", fontFamily: "Courier New" }}></textarea>
                            </div>
                        </div>
                    </div>
                </div>



            </div>




        )
    }
}

export default ColSecond