import { observable } from 'mobx'

class TableStore {
    @observable fieldsStore = []
    @observable whereStore = []
    @observable code = ""
    @observable working = ""
    @observable ifNull = false
    @observable colOf = false
}

export default TableStore = new TableStore()