
class GenCode {

    tableName = ""
    descTable = ""
    hasColOf = true
    hasIfNull = false

    setIfNull = (opt) => {
        this.hasIfNull = opt
        console.log('ifNull:', this.hasIfNull)
    }

    loadFields(content) {

        let line = ''
        let fields = []

        let restLinha = ""
        let fieldName = ""
        let fieldKind = ""
        let fieldLength = ""
        let fieldInt = ""
        let fieldDec = ""
        let tableName = ""
        let descTable = ""
        let isNull = false
        let checked = false
        let firstTime = true

        let lengthInt = 0
        let lengthDec = 0
        let posIni = 0
        let posFin = 0
        let keyId = 0

        posIni = content.indexOf(" DECLARE ") + 9
        posFin = content.indexOf(" TABLE", posIni)
        descTable = content.substring(posIni, posFin).trim()
        this.descTable = descTable

        posIni = content.indexOf("STRUCTURE(") + 10
        posFin = content.indexOf(")", posIni)
        tableName = content.substring(posIni, posFin).trim()
        this.tableName = tableName


        posIni = 0
        posFin = 0

        const lines = content.split("\n")

        for (let i = 0; i <= lines.length; i++) {

            line = lines[i]

            if (line.includes("END-EXEC.")) {
                return fields
            }

            if (line.includes(" ( ")) {
                posIni = line.indexOf(" ( ")
                posIni = posIni + 3
            }

            if (posIni > 0) {
                fieldName = line.substring(posIni)
                posFin = fieldName.indexOf(" ")
                restLinha = fieldName.substring(posFin).trim()
                fieldName = fieldName.substring(0, posFin)

                posFin = restLinha.indexOf("(")

                if (posFin > 0) {
                    fieldKind = restLinha.substring(0, posFin)

                    restLinha = restLinha.substring(posFin)
                    posFin = restLinha.indexOf(")")

                    fieldLength = restLinha.substring(1, posFin).trim()

                    if (fieldLength.includes(",")) {
                        posFin = fieldLength.indexOf(",")
                        fieldInt = fieldLength.substring(0, posFin)
                        posFin = posFin + 1
                        fieldDec = fieldLength.substring(posFin).trim()
                        lengthInt = parseInt(fieldInt)
                        lengthDec = parseInt(fieldDec)
                    } else {
                        lengthInt = parseInt(fieldLength)
                        lengthDec = 0
                    }

                } else {
                    posFin = restLinha.indexOf(" NOT NULL")
                    if (posFin > 0) {
                        fieldKind = restLinha.substring(0, posFin)
                    } else {
                        fieldKind = restLinha.substring(0)
                    }

                }

                fieldKind = fieldKind.split(",").join("")

                if (fieldKind === "TIMESTAMP") {
                    lengthInt = 26
                    lengthDec = 0
                }

                if (fieldKind === "DATE") {
                    lengthInt = 10
                    lengthDec = 0
                }

                if (restLinha.includes("NOT NULL")) {
                    isNull = false
                    if (firstTime) {
                        keyId = keyId + 1
                    }
                } else {
                    firstTime = false
                    isNull = true
                    keyId = 0
                }


                fields.push({ fieldName, fieldKind, lengthInt, lengthDec, isNull, keyId, checked })

            }
        }

    }

    handleWorking = (fields, opt) => {

        let working = ''

        if (!this.hasIfNull || opt) {
            working = this.handleNullWorking(fields)
            working = working + "      *\n"
        }

        working = working + this.handleIncludeWorking()

        return working
    }


    handleSelect = (fields, where) => {

        let code = ''
        let i = 0
        let firstTime = true
        let hasNull = false

        code = "      *----------------------------------------------------------------*\n"
        code = code + "       2100-00-SELECTION-" + this.tableName + "      SECTION.\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "      *\n"

        // Moving data to WHERE fields
        for (i = 0; i < where.length; i++) {
            let field = where[i]
            if (field.checked) {

                let fieldName = field.fieldName.split('_').join('-')
                let spacesField = (18 - fieldName.length)
                if (spacesField < 0) {
                    spacesField = 0
                    this.hasColOf = false
                }
                fieldName = fieldName + " ".repeat(spacesField)

                if (this.hasColOf) {
                    code = code + "           MOVE XXXXXXXXXXXXXXXXXXXXXXXXXX\n"
                    code = code + "                                       TO " + fieldName + " OF " + this.tableName + "\n"
                } else {
                    code = code + "           MOVE XXXXXXXXXXXXXXXXXXXXXXXXXX\n"
                    code = code + "                                       TO " + fieldName + "\n"
                    code = code + "                                       OF " + this.tableName + "\n"
                    this.hasColOf = true
                }

            }
        }

        code = code + "      *\n"
        code = code + "           EXEC SQL\n"

        // Mount fields of SELECT
        firstTime = true
        for (i = 0; i < fields.length; i++) {
            let field = fields[i]

            let fieldName = field.fieldName
            if (field.checked) {

                if (this.hasIfNull) {
                    if (field.isNull) {
                        fieldName = this.formatField(fieldName, field.fieldKind)
                    }
                }

                if (firstTime) {
                    code = code + "             SELECT " + fieldName + "\n"
                    firstTime = false
                } else {
                    code = code + "                   ," + fieldName + "\n"
                }

            }
        }

        // Mount fields of INTO
        firstTime = true
        for (i = 0; i < fields.length; i++) {
            let field = fields[i]

            if (field.checked) {
                let fieldNameVar = field.fieldName.split("_").join("-")

                if (firstTime) {
                    code = code + "               INTO :" + this.tableName + "." + fieldNameVar + "\n"
                    firstTime = false
                } else {
                    code = code + "                   ,:" + this.tableName + "." + fieldNameVar + "\n"
                }

                if (!this.hasIfNull) {
                    if (field.isNull) {
                        hasNull = true
                        code = code + "                      :WK-" + fieldNameVar + "-N\n"
                    }
                }

            }
        }

        // Description of the table
        code = code + "               FROM " + this.descTable + "\n"

        // Setting data to WHERE fields in SELECT
        firstTime = true
        for (i = 0; i < where.length; i++) {
            let field = where[i]
            if (field.checked) {

                let spacesField = (19 - field.fieldName.length)
                if (spacesField < 0) {
                    spacesField = 0
                }
                let fieldName = field.fieldName + " ".repeat(spacesField)
                let fieldNameAux = field.fieldName.split("_").join("-")

                if (firstTime) {
                    code = code + "              WHERE " + fieldName + "= :" + this.tableName + "." + fieldNameAux + "\n"
                    firstTime = false
                } else {
                    code = code + "                AND " + fieldName + "= :" + this.tableName + "." + fieldNameAux + "\n"
                }
            }
        }

        code = code + "           END-EXEC.\n"
        code = code + "      *\n"
        code = code + "           IF (SQLCODE                 NOT EQUAL ZEROS AND +100)\n"
        code = code + "           OR (SQLWARN0                EQUAL 'W')\n"
        code = code + "               SET DB2-SELECT          TO TRUE\n"
        code = code + "           END-IF.\n"

        if (!this.hasIfNull) {
            if (hasNull) {
                code = code + "      *\n"
                code = code + "           IF SQLCODE                  EQUAL ZEROS\n"
                code = code + "               PERFORM 2150-00-HANDLE-NULL-" + this.tableName + "\n"
                code = code + "           END-IF.\n"
            }
        }

        code = code + "      *\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "       2100-99-SELECTION-" + this.tableName + ".     EXIT.\n"
        code = code + "      *----------------------------------------------------------------*\n"

        if (!this.hasIfNull) {
            if (hasNull) {
                code = code + this.handleNull("2150", fields)
            }
        }

        return code

    }

    formatField = (fieldName, fieldKind) => {

        let fieldFormated = ""

        if (fieldKind === "DECIMAL") {
            fieldFormated = "IFNULL(" + fieldName + ", 0)"
        }

        if (fieldKind === "SMALLINT") {
            fieldFormated = "IFNULL(" + fieldName + ", 0)"
        }

        if (fieldKind === "INTEGER") {
            fieldFormated = "IFNULL(" + fieldName + ", 0)"
        }

        if (fieldKind === "CHAR") {
            fieldFormated = "IFNULL(" + fieldName + ", ' ')"
        }

        if (fieldKind === "DATE") {
            fieldFormated = "IFNULL(" + fieldName + ", '01.01.0001')"
        }

        if (fieldKind === "TIME") {
            fieldFormated = "IFNULL(" + fieldName + ", '00.00.00')"
        }

        if (fieldKind === "TIMESTAMP") {
            fieldFormated = "IFNULL(" + fieldName + ", '0001-01-01-00.00.00.000000')"
        }

        return fieldFormated

    }



    handleNull = (numProcess, fields) => {

        let fieldName = ""

        let code = "      *----------------------------------------------------------------*\n"
        code = code + "       " + numProcess + "-00-HANDLE-NULL-" + this.tableName + "    SECTION.\n"
        code = code + "      *----------------------------------------------------------------*\n"

        for (let i = 0; i < fields.length; i++) {
            let field = fields[i]

            if (field.checked) {

                if (field.isNull) {

                    fieldName = 'WK-' + field.fieldName.split('_').join('-').trim() + "-N"

                    if (fieldName.length < 24) {
                        let spacesField = (23 - fieldName.length)
                        if (spacesField < 0) {
                            spacesField = 0
                        }
                        fieldName = fieldName + " ".repeat(spacesField)
                    }

                    code = code + "      *\n"

                    if (fieldName.length < 24) {
                        code = code + "           IF  " + fieldName + " LESS ZEROS\n"
                    } else {
                        code = code + "           IF  " + fieldName + "\n"
                        code = code + "                                       LESS ZEROS\n"
                    }

                    fieldName = field.fieldName.split('_').join('-').trim()
                    if (fieldName.length < 18) {
                        let spacesField = (18 - fieldName.length)
                        if (spacesField < 0) {
                            spacesField = 0
                            this.hasColOf = false
                        }
                        fieldName = fieldName + " ".repeat(spacesField)
                    }

                    if (field.fieldKind === "DECIMAL") {
                        if (this.hasColOf) {
                            code = code + "               MOVE ZEROS              TO " + fieldName + " OF " + this.tableName + "\n"

                        } else {
                            code = code + "               MOVE ZEROS              TO " + fieldName + "\n"
                            code = code + "                                       OF " + this.tableName + "\n"
                            this.hasColOf = true
                        }
                    }

                    if (field.fieldKind === "CHAR") {
                        if (this.hasColOf) {
                            code = code + "               MOVE SPACES             TO " + fieldName + " OF " + this.tableName + "\n"
                        } else {
                            code = code + "               MOVE SPACES             TO " + fieldName + "\n"
                            code = code + "                                       OF " + this.tableName + "\n"
                        }

                    }

                    if (field.fieldKind === "DATE") {
                        if (this.hasColOf) {
                            code = code + "               MOVE '0001.01.01'       TO " + fieldName + " OF " + this.tableName + "\n"
                        } else {
                            code = code + "               MOVE '0001.01.01'       TO " + fieldName + "\n"
                            code = code + "                                       OF " + this.tableName + "\n"
                        }
                    }

                    if (field.fieldKind === "TIMESTAMP") {
                        if (this.hasColOf) {
                            code = code + "               MOVE '0001-01-01-00.00.00.000000'\n"
                            code = code + "                                       TO " + fieldName + " OF " + this.tableName + "\n"
                        } else {
                            code = code + "               MOVE '0001-01-01-00.00.00.000000'\n"
                            code = code + "                                       TO " + fieldName + "\n"
                            code = code + "                                       OF " + this.tableName + "\n"
                        }
                    }

                    code = code + "           END-IF.\n"
                }

            }
        }

        code = code + "      *\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "       " + numProcess + "-99-HANDLE-NULL-" + this.tableName + ".   EXIT.\n"
        code = code + "      *----------------------------------------------------------------*\n"

        return code

    }

    handleIncludeWorking = () => {

        let working = ''

        working = "      *----------------------------------------------------------------*\n"
        working = working + "       01  FILLER                      PIC X(050)      VALUE\n"
        working = working + "           'AREA PARA DB2'.\n"
        working = working + "      *----------------------------------------------------------------*\n"
        working = working + "      *\n"
        working = working + "           EXEC SQL  INCLUDE SQLCA      END-EXEC.\n"
        working = working + "           EXEC SQL  INCLUDE " + this.tableName + "   END-EXEC.\n"
        working = working + "      *\n"

        return working

    }


    handleNullWorking = (fields) => {

        let working = ''
        let firstTime = true

        for (let i = 0; i < fields.length; i++) {
            let field = fields[i]

            if (field.checked) {
                if (field.isNull) {
                    if (firstTime) {
                        working = "      *----------------------------------------------------------------*\n"
                        working = working + "       01  FILLER                          PIC  X(031) VALUE\n"
                        working = working + "           'WORKING AREA FOR NULL FIELDS'.\n"
                        working = working + "      *----------------------------------------------------------------*\n"
                        working = working + "      *\n"
                        working = working + "       01 WK-NULL-" + this.tableName + ".\n"
                        firstTime = false
                    }


                    let fieldName = "WK-" + field.fieldName.split('_').join('-') + "-N"
                    let fieldLength = fieldName.length

                    if (fieldLength < 29) {
                        let spacesField = (29 - fieldName.length)
                        if (spacesField < 0) {
                            spacesField = 0
                        }
                        fieldName = fieldName + " ".repeat(spacesField)
                    }

                    if (fieldLength < 30) {
                        working = working + "          05 " + fieldName + " PIC S9(004) COMP VALUE +0.\n"
                    } else {
                        working = working + "          05 " + fieldName + "\n"
                        working = working + "                                           PIC S9(004) COMP VALUE +0.\n"
                    }

                }

            }

        }

        return working

    }

    handleInsert = (fields) => {

        let code = ''
        let i = 0
        let firstTime = true
        let hasNull = false

        code = "      *----------------------------------------------------------------*\n"
        code = code + "       2100-00-INSERT-" + this.tableName + "         SECTION.\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "      *\n"
        code = code + "           PERFORM 2110-00-MOVE-DATA-" + this.tableName + "\n"
        code = code + "      *\n"
        code = code + "           EXEC SQL\n"
        code = code + "                INSERT INTO " + this.descTable + "\n"

        // Mount fields of INSERT
        firstTime = true
        for (i = 0; i < fields.length; i++) {
            let field = fields[i]

            let fieldName = field.fieldName.trim()
            if (field.checked) {
                // if (this.hasIfNull) {
                //     if (field.isNull) {
                //         fieldName = this.formatField(fieldName, field.fieldKind)
                //     }
                // }
                if (firstTime) {
                    code = code + "                   (" + fieldName + "\n"
                    firstTime = false
                } else {
                    code = code + "                   ," + fieldName + "\n"
                }
            }
        }

        code = code + "                   )\n"
        code = code + "                VALUES\n"

        // Mount fields of VALUES
        firstTime = true
        for (i = 0; i < fields.length; i++) {
            let field = fields[i]
            let fieldName = field.fieldName
            if (field.checked) {
                if (firstTime) {
                    code = code + "                  ( :" + this.tableName + "." + fieldName.split("_").join("-") + "\n"
                    firstTime = false
                } else {
                    code = code + "                   ,:" + this.tableName + "." + fieldName.split("_").join("-") + "\n"
                }
                // if (!this.hasIfNull) {
                if (field.isNull) {
                    code = code + "                      :WK-" + fieldName.split("_").join("-").trim() + "-N\n"
                    hasNull = true
                }
                // }
            }
        }

        code = code + "                   )\n"
        code = code + "           END-EXEC.\n"
        code = code + "      *\n"
        code = code + "           IF (SQLCODE                 NOT EQUAL ZEROS AND +100)\n"
        code = code + "           OR (SQLWARN0                EQUAL 'W')\n"
        code = code + "               SET DB2-INSERT          TO TRUE\n"
        code = code + "           END-IF.\n"
        code = code + "      *\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "       2100-99-INSERT-" + this.tableName + ".        EXIT.\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "      *\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "       2110-00-MOVE-DATA-" + this.tableName + "      SECTION.\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "      *\n"

        code = code + this.handleMove(fields, hasNull)

        code = code + "      *\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "       2110-99-MOVE-DATA-" + this.tableName + ".     EXIT.\n"
        code = code + "      *----------------------------------------------------------------*\n"

        return code
    }


    handleMove = (fields, hasNull) => {

        let code = ''
        //let firstTime = true

        if (!this.hasIfNull) {
            if (hasNull) {
                code = code + "           INITIALIZE WK-NULL-" + this.tableName + ".\n"
                code = code + "      *\n"
            }
        }

        // Mount MOVE
        for (let i = 0; i < fields.length; i++) {
            let field = fields[i]
            if (field.checked) {
                let fieldName = field.fieldName.split('_').join('-')
                let spacesField = (18 - fieldName.length)

                if (spacesField < 0) {
                    spacesField = 0
                    this.hasColOf = false
                }

                fieldName = fieldName + " ".repeat(spacesField)
                if (this.hasColOf) {
                    code = code + "           MOVE XXXXXXXXXXXXXXXXXXXXXXXXXX\n"
                    code = code + "                                       TO " + fieldName + " OF " + this.tableName + "\n"
                } else {
                    code = code + "           MOVE XXXXXXXXXXXXXXXXXXXXXXXXXX\n"
                    code = code + "                                       TO " + fieldName + "\n"
                    code = code + "                                       OF " + this.tableName + "\n"
                    this.hasColOf = true
                }
            }
        }

        for (let i = 0; i < fields.length; i++) {
            let field = fields[i]
            if (field.checked) {
                if (field.isNull) {
                    let fieldName = field.fieldName.split('_').join('-').trim()
                    if (fieldName.length < 24) {
                        let spacesField = (23 - fieldName.length)
                        if (spacesField < 0) {
                            spacesField = 0
                        }
                        fieldName = fieldName + " ".repeat(spacesField)
                    }

                    let kindField = ''
                    field.fieldKind === 'DECIMAL' ? kindField = 'ZEROS' : kindField = 'SPACES'

                    // if (!this.hasIfNull) {
                    if (field.isNull) {
                        code = code + "      *\n"
                        code = code + "           IF  " + fieldName + " OF " + this.tableName + " EQUAL " + kindField + "\n"
                        code = code + "               MOVE -1                 TO WK-" + field.fieldName.split('_').join('-').trim() + "-N\n"
                        code = code + "           END-IF.\n"
                    }
                    // } else {
                    //     if (firstTime) {
                    //         code = code + "           .\n"
                    //         firstTime = false
                    //     }
                    // }

                }

            }
        }

        return code

    }

    handleUpdate = (fields, where) => {

        let code = ''
        let i = 0
        let firstTime = true
        let hasNull = false

        code = "      *----------------------------------------------------------------*\n"
        code = code + "       2100-00-UPDATE-" + this.tableName + "         SECTION.\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "      *\n"
        code = code + "           PERFORM 2110-00-MOVE-DATA-" + this.tableName + "\n"


        // Moving fields key for update
        for (i = 0; i < where.length; i++) {
            let field = where[i]
            if (field.checked) {

                let fieldName = field.fieldName.split('_').join('-')
                let spacesField = (18 - fieldName.length)
                if (spacesField < 0) {
                    spacesField = 0
                    this.hasColOf = false
                }
                fieldName = fieldName + " ".repeat(spacesField)

                if (this.hasColOf) {
                    if (firstTime) {
                        code = code + "      *\n"
                        firstTime = false
                    }
                    code = code + "           MOVE XXXXXXXXXXXXXXXXXXXXXXXXXX\n"
                    code = code + "                                       TO " + fieldName + " OF " + this.tableName + "\n"
                } else {
                    if (firstTime) {
                        code = code + "      *\n"
                        firstTime = false
                    }
                    code = code + "           MOVE XXXXXXXXXXXXXXXXXXXXXXXXXX\n"
                    code = code + "                                       TO " + fieldName + "\n"
                    code = code + "                                       OF " + this.tableName + "\n"
                    this.hasColOf = true
                }

            }
        }

        code = code + "      *\n"
        code = code + "           EXEC SQL\n"
        code = code + "             UPDATE " + this.descTable + "\n"

        // Mount fields of UPDATE
        firstTime = true
        for (i = 0; i < fields.length; i++) {
            let field = fields[i]

            let fieldName = field.fieldName
            if (field.checked) {

                if (fieldName.length < 19) {
                    let spacesField = (18 - fieldName.length)
                    if (spacesField < 0) {
                        spacesField = 0
                    }
                    fieldName = fieldName + " ".repeat(spacesField)
                }

                if (firstTime) {
                    code = code + "                SET " + fieldName + " = :" + this.tableName + "." + field.fieldName.split('_').join('-').trim() + "\n"
                    firstTime = false
                } else {
                    code = code + "                   ," + fieldName + " = :" + this.tableName + "." + field.fieldName.split('_').join('-').trim() + "\n"
                }

                // if (!this.hasIfNull) {
                if (field.isNull) {
                    code = code + "                      :WK-" + fieldName.split("_").join("-").trim() + "-N\n"
                    hasNull = true
                }
                // }

            }
        }

        // Setting data to WHERE fields in UPDATE
        firstTime = true
        for (i = 0; i < where.length; i++) {
            let field = where[i]
            if (field.checked) {

                let spacesField = (19 - field.fieldName.length)
                if (spacesField < 0) {
                    spacesField = 0
                }
                let fieldName = field.fieldName + " ".repeat(spacesField)
                let fieldNameAux = field.fieldName.split("_").join("-")

                if (firstTime) {
                    code = code + "              WHERE " + fieldName + "= :" + this.tableName + "." + fieldNameAux + "\n"
                    firstTime = false
                } else {
                    code = code + "                AND " + fieldName + "= :" + this.tableName + "." + fieldNameAux + "\n"
                }
            }
        }

        code = code + "           END-EXEC.\n"
        code = code + "      *\n"
        code = code + "           IF (SQLCODE                 NOT EQUAL ZEROS AND +100)\n"
        code = code + "           OR (SQLWARN0                EQUAL 'W')\n"
        code = code + "               SET DB2-UPDATE          TO TRUE\n"
        code = code + "           END-IF.\n"
        code = code + "      *\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "       2100-99-UPDATE-" + this.tableName + ".        EXIT.\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "      *\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "       2110-00-MOVE-DATA-" + this.tableName + "      SECTION.\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "      *\n"

        code = code + this.handleMove(fields, hasNull)

        code = code + "      *\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "       2110-99-MOVE-DATA-" + this.tableName + ".     EXIT.\n"
        code = code + "      *----------------------------------------------------------------*\n"

        return code
    }


    handleDelete = (where) => {

        let code = ''
        let i = 0
        let firstTime = true

        code = "      *----------------------------------------------------------------*\n"
        code = code + "       2100-00-DELETE-" + this.tableName + "         SECTION.\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "      *\n"

        // Moving fields key for DELETE
        for (i = 0; i < where.length; i++) {
            let field = where[i]
            if (field.checked) {

                let fieldName = field.fieldName.split('_').join('-')
                let spacesField = (18 - fieldName.length)
                if (spacesField < 0) {
                    spacesField = 0
                    this.hasColOf = false
                }
                fieldName = fieldName + " ".repeat(spacesField)

                if (this.hasColOf) {
                    code = code + "           MOVE XXXXXXXXXXXXXXXXXXXXXXXXXX\n"
                    code = code + "                                       TO " + fieldName + " OF " + this.tableName + "\n"
                } else {
                    code = code + "           MOVE XXXXXXXXXXXXXXXXXXXXXXXXXX\n"
                    code = code + "                                       TO " + fieldName + "\n"
                    code = code + "                                       OF " + this.tableName + "\n"
                    this.hasColOf = true
                }

            }
        }

        code = code + "      *\n"
        code = code + "           EXEC SQL\n"
        code = code + "             DELETE\n"
        code = code + "               FROM " + this.descTable + "\n"

        // Setting data to WHERE fields in DELETE
        firstTime = true
        for (i = 0; i < where.length; i++) {
            let field = where[i]
            if (field.checked) {

                let spacesField = (19 - field.fieldName.length)
                if (spacesField < 0) {
                    spacesField = 0
                }
                let fieldName = field.fieldName + " ".repeat(spacesField)
                let fieldNameAux = field.fieldName.split("_").join("-")

                if (firstTime) {
                    code = code + "              WHERE " + fieldName + "= :" + this.tableName + "." + fieldNameAux + "\n"
                    firstTime = false
                } else {
                    code = code + "                AND " + fieldName + "= :" + this.tableName + "." + fieldNameAux + "\n"
                }
            }
        }

        code = code + "           END-EXEC.\n"
        code = code + "      *\n"
        code = code + "           IF (SQLCODE                 NOT EQUAL ZEROS AND +100)\n"
        code = code + "           OR (SQLWARN0                EQUAL 'W')\n"
        code = code + "               SET DB2-DELETE          TO TRUE\n"
        code = code + "           END-IF.\n"
        code = code + "      *\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "       2100-99-DELETE-" + this.tableName + ".        EXIT.\n"
        code = code + "      *----------------------------------------------------------------*\n"

        return code
    }

    handleWorkingCursor = (fields, where, opt) => {

        let working = ''
        let cursorName = "CSR01-" + this.tableName
        let firstTime = true

        if (!this.hasIfNull || opt) {
            working = this.handleNullWorking(fields)
            if (working !== '') {
                working = working + "      *\n"
            }
        }

        working = working + "      *----------------------------------------------------------------*\n"
        working = working + "       77  FILLER                      PIC  X(050)     VALUE\n"
        working = working + "           'AUXILIARY WORKING AREA'.\n"
        working = working + "      *----------------------------------------------------------------*\n"
        working = working + "      *\n"
        working = working + "       01  WRK-AUXILIARY-AREA.\n"
        working = working + "           05  WRK-END-CSR01           PIC  X(001)     VALUE SPACES.\n"
        working = working + "               88 WRK-END-CSR01-Y                      VALUE 'Y'.\n"
        working = working + "               88 WRK-END-CSR01-N                      VALUE 'N'.\n"
        working = working + "      *\n"

        working = working + this.handleIncludeWorking()

        working = working + "      *----------------------------------------------------------------*\n"
        working = working + "       01  FILLER                      PIC X(050)      VALUE\n"
        working = working + "           'AREA PARA CURSORES'.\n"
        working = working + "      *----------------------------------------------------------------*\n"
        working = working + "      *\n"
        working = working + "           EXEC SQL\n"
        working = working + "            DECLARE " + cursorName + " CURSOR FOR\n"

        firstTime = true
        for (let i = 0; i < fields.length; i++) {
            let field = fields[i]

            let fieldName = field.fieldName
            if (field.checked) {

                if (this.hasIfNull) {
                    if (field.isNull) {
                        fieldName = this.formatField(fieldName, field.fieldKind)
                    }
                }

                if (firstTime) {
                    working = working + "             SELECT " + fieldName + "\n"
                    firstTime = false
                } else {
                    working = working + "                   ," + fieldName + "\n"
                }

            }
        }

        working = working + "               FROM " + this.descTable + "\n"

        firstTime = true
        for (let i = 0; i < where.length; i++) {
            let field = where[i]
            if (field.checked) {

                let spacesField = (19 - field.fieldName.length)
                if (spacesField < 0) {
                    spacesField = 0
                }
                let fieldName = field.fieldName + " ".repeat(spacesField)
                let fieldNameAux = field.fieldName.split("_").join("-")

                if (firstTime) {
                    working = working + "              WHERE " + fieldName + "= :" + this.tableName + "." + fieldNameAux + "\n"
                    firstTime = false
                } else {
                    working = working + "                AND " + fieldName + "= :" + this.tableName + "." + fieldNameAux + "\n"
                }
            }
        }

        working = working + "           END-EXEC.\n"

        return working

    }

    handleCursor = (fields, where) => {

        let code = ''
        let i = 0
        let firstTime = true
        let hasNull = false
        let cursorName = "CSR01-" + this.tableName

        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "       2000-00-PROCESS-CSR01           SECTION.\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "      *\n"

        code = code + "           PERFORM 2100-00-OPEN-" + cursorName + ".\n"
        code = code + "      *\n"
        code = code + "           PERFORM 2200-00-READ-" + cursorName + "\n"
        code = code + "                                       UNTIL WRK-END-CSR01-Y.\n"
        code = code + "      *\n"
        code = code + "           PERFORM 2300-00-CLOSE-" + cursorName + ".\n"
        code = code + "      *\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "       2000-99-PROCESS-CSR01.          EXIT.\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "      *\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "       2100-00-OPEN-" + cursorName + "     SECTION.\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "      *\n"
        code = code + "           SET WRK-FIM-CSR01-N         TO TRUE\n"

        // Moving data to WHERE fields
        firstTime = true
        for (i = 0; i < where.length; i++) {
            let field = where[i]
            if (field.checked) {

                let fieldName = field.fieldName.split('_').join('-')
                let spacesField = (18 - fieldName.length)
                if (spacesField < 0) {
                    spacesField = 0
                    this.hasColOf = false
                }
                fieldName = fieldName + " ".repeat(spacesField)

                if (firstTime) {
                    code = code + "      *\n"
                }
                if (this.hasColOf) {
                    code = code + "           MOVE XXXXXXXXXXXXXXXXXXXXXXXXXX\n"
                    code = code + "                                       TO " + fieldName + " OF " + this.tableName + "\n"
                } else {
                    code = code + "           MOVE XXXXXXXXXXXXXXXXXXXXXXXXXX\n"
                    code = code + "                                       TO " + fieldName + "\n"
                    code = code + "                                       OF " + this.tableName + "\n"
                    this.hasColOf = true
                }

            }
        }

        code = code + "      *\n"
        code = code + "           EXEC SQL\n"
        code = code + "                OPEN " + cursorName + "\n"
        code = code + "           END-EXEC\n"
        code = code + "      *\n"
        code = code + "           IF (SQLCODE                 NOT EQUAL ZEROS)\n"
        code = code + "           OR (SQLWARN0                EQUAL 'W')\n"
        code = code + "               SET DB2-OPEN            TO TRUE\n"
        code = code + "           END-IF.\n"
        code = code + "      *\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "       2100-99-OPEN-" + cursorName + ".    EXIT.\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "      *\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "       2200-00-READ-" + cursorName + "     SECTION.\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "      *\n"

        code = code + "           EXEC SQL\n"
        code = code + "              FETCH " + cursorName + "\n"

        // Mount fields of INTO
        firstTime = true
        for (i = 0; i < fields.length; i++) {
            let field = fields[i]

            if (field.checked) {
                let fieldNameVar = field.fieldName.split("_").join("-")

                if (firstTime) {
                    code = code + "               INTO :" + this.tableName + "." + fieldNameVar + "\n"
                    firstTime = false
                } else {
                    code = code + "                   ,:" + this.tableName + "." + fieldNameVar + "\n"
                }

                if (!this.hasIfNull) {
                    if (field.isNull) {
                        hasNull = true
                        code = code + "                      :WK-" + fieldNameVar + "-N\n"
                    }
                }

            }
        }

        code = code + "           END-EXEC\n"
        code = code + "      *\n"
        code = code + "           IF (SQLCODE                 NOT EQUAL ZEROS AND +100)\n"
        code = code + "           OR (SQLWARN0                EQUAL 'W')\n"
        code = code + "               SET DB2-FETCH           TO TRUE\n"
        code = code + "           END-IF.\n"
        code = code + "      *\n"


        code = code + "           IF  SQLCODE                 EQUAL +100\n"
        code = code + "               SET WRK-END-CSR01-Y     TO TRUE\n"
        code = code + "           ELSE\n"

        if (!this.hasIfNull) {
            if (hasNull) {
                code = code + "               PERFORM 2210-00-HANDLE-NULL-" + this.tableName + "\n"
            } else {
                code = code + "               CONTINUE\n"
            }
        } else {
            code = code + "               CONTINUE\n"
        }

        code = code + "      ******   INCLUDE HERE WHATEVER YOU WANT \n"
        code = code + "           END-IF.\n"
        code = code + "      *\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "       2200-99-READ-" + cursorName + ".    EXIT.\n"
        code = code + "      *----------------------------------------------------------------*\n"

        if (!this.hasIfNull) {
            if (hasNull) {
                code = code + this.handleNull("2210", fields)
            }
        }

        code = code + "      *\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "       2300-00-CLOSE-" + cursorName + "    SECTION.\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "      *\n"
        code = code + "           EXEC SQL\n"
        code = code + "                CLOSE " + cursorName + "\n"
        code = code + "           END-EXEC\n"
        code = code + "      *\n"
        code = code + "           IF (SQLCODE                 NOT EQUAL ZEROS)\n"
        code = code + "           OR (SQLWARN0                EQUAL 'W')\n"
        code = code + "               SET DB2-CLOSE           TO TRUE\n"
        code = code + "           END-IF.\n"
        code = code + "      *\n"
        code = code + "      *----------------------------------------------------------------*\n"
        code = code + "       2300-99-CLOSE-" + cursorName + ".   EXIT.\n"
        code = code + "      *----------------------------------------------------------------*\n"

        return code

    }


}

export default GenCode = new GenCode()
