import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { inject, observer } from 'mobx-react';

import GenCode from './GenCode'

@inject('TableStore')
@observer
class ColFirst extends Component {

    fileReader

    state = {
        collapsedFields: true,
        collapsedConditions: true,
        index: Array.from(Array(40).keys()),
        fields: [],
        where: [],
        selectedAll: false
    };

    componentDidMount() {
        // this.fileReader = new FileReader();
        // this.fileReader.onloadend = this.handleFileRead;
        // this.fileReader.readAsText('/sample.cpy');

        let content = ''
        fetch('/CONTRACT.cpy')
            .then((r) => r.text())
            .then(text => {
                content = text
                let fields = GenCode.loadFields(content)
                let where = GenCode.loadFields(content)

                this.setState({ fields: fields })
                this.setState({ where: where })

                this.props.TableStore.fieldsStore = fields
                this.props.TableStore.whereStore = where

                this.props.TableStore.code = ""
                this.setState({
                    selectedAll: false,
                    collapsedFields: true,
                    collapsedConditions: true
                });
            })
    }


    toggleCollapse = () => {
        this.setState({
            collapsedFields: !this.state.collapsedFields,
            collapsedConditions: !this.state.collapsedConditions
        });
    }

    handleChange = (e) => {
        let file = e[0];
        if (file) {
            this.fileReader = new FileReader();
            this.fileReader.onloadend = this.handleFileRead;
            this.fileReader.readAsText(file);
        }
    }

    handleFileRead = (e) => {
        let content = this.fileReader.result;
        let fields = GenCode.loadFields(content)
        let where = GenCode.loadFields(content)

        this.setState({ fields: fields })
        this.setState({ where: where })

        this.props.TableStore.fieldsStore = fields
        this.props.TableStore.whereStore = where

        this.props.TableStore.code = ""
        this.setState({
            selectedAll: false,
            collapsedFields: true,
            collapsedConditions: true
        });


    };

    toggleChangeCheckFields = (index, value) => {

        let fields = this.state.fields
        let field = fields[index]

        field.checked = !value
        fields.splice(index, field)

        this.setState({ fields: fields })
        this.props.TableStore.fieldsStore = fields
    }

    toggleChangeCheckWhere = (index, value) => {

        let fields = this.state.where
        let field = fields[index]

        field.checked = !value
        fields.splice(index, field)

        this.setState({ where: fields })
        this.props.TableStore.whereStore = fields
    }

    handleSelectAll = () => {

        let value = !this.state.selectedAll
        let fields = this.state.fields

        for (let i = 0; i < fields.length; i++) {
            //console.log(fields[i].checked)
            fields[i].checked = value
        }

        this.setState({ fields: fields })
        this.setState({ selectedAll: value })
        this.props.TableStore.fieldsStore = fields

    }

    render() {

        const collapsedFields = this.state.collapsedFields;
        const collapsedConditions = this.state.collapsedConditions;
        const classFields = collapsedFields ? 'collapse show' : 'collapse';
        const classConditions = collapsedConditions ? 'collapse ' : 'collapse show';

        return (

            <div className="col-sm-4 ">

                <div className="mb-2">

                    <div className="custom-file">
                        <input type="file" className="custom-file-input"
                            onChange={(e) => this.handleChange(e.target.files)}
                            id="inputFile"
                        />
                        <label className="custom-file-label" htmlFor="inputFile">Choose DCLGEN file</label>
                    </div>
                </div>

                <div className="accordion" id="tableFields">

                    <div className="card">
                        <div className="card-header p-0" id="fields">
                            <button onClick={this.toggleCollapse} className="btn btn-link"
                                type="button"
                                data-toggle="collapse" data-target="#collapseFields"
                                aria-expanded="true" aria-controls="collapseFields">
                                Table Fields
                            </button>
                            <div className="btn btn-sm btn float-right"
                                onClick={this.handleSelectAll}
                                data-toggle="tooltip" data-placement="top" title="Select all">
                                <FontAwesomeIcon icon="tasks" />
                            </div>

                        </div>

                        <div id="collapseFields" className={`${classFields}`} aria-labelledby="fields" data-parent="#tableFields">
                            <div className="card-body" style={{ height: "70vh" }}>
                                <div className="form-group form-check" style={{ overflowY: "scroll", height: "100%" }}>
                                    <div className="small">
                                        {this.state.fields.map((item, index) => (
                                            <div key={index}>
                                                <input type="checkbox"
                                                    checked={item.checked}
                                                    onChange={() => this.toggleChangeCheckFields(index, item.checked)}
                                                    className="form-check-input" />
                                                <div className="text"
                                                    onClick={() => this.toggleChangeCheckFields(index, item.checked)}
                                                >{item.fieldName}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-2">
                        <div className="card-header p-0" id="whereCondition">
                            <h5 className="mb-0">
                                <button
                                    onClick={this.toggleCollapse}
                                    className="btn btn-link"
                                    type="button" data-toggle="collapse"
                                    data-target="#collapseCondition"
                                    aria-expanded="false" aria-controls="collapseCondition">
                                    Condition (where)
                            </button>
                            </h5>
                        </div>
                        <div id="collapseCondition" className={`${classConditions}`} aria-labelledby="whereCondition" data-parent="#tableFields">
                            <div className="card-body" style={{ height: " 70vh" }}>
                                <div className="form-group form-check" style={{ overflowY: "scroll", height: "100%" }}>
                                    <div className="small">
                                        {this.state.where.map((item, index) => (
                                            <div key={index}>
                                                <input type="checkbox"
                                                    checked={item.checked}
                                                    onChange={() => this.toggleChangeCheckWhere(index, item.checked)}
                                                    className="form-check-input" />
                                                <div className="text"
                                                    onClick={() => this.toggleChangeCheckWhere(index, item.checked)}
                                                >{item.fieldName}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}

export default ColFirst