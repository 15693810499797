import React from "react";

const footerStyle = {
  backgroundColor: "#343a40",
  fontSize: "14px",
  color: "white",
  borderTop: "1px solid #E7E7E7",
  textAlign: "center",
  padding: "10px",
  // position: "fixed",
  left: "0",
  bottom: "0",
  // height: "70px",
  width: "100%",
};

// const phantomStyle = {
//   display: "block",
//   padding: "10px",
//   height: "40px",
//   width: "100%",
// };

const Footer = (props) => {
  return (
    <div>

      <div style={footerStyle}>
        <span style={{ fontStyle: "italic" }}>2018 Rodrigo Santos - </span>
        <a
          style={{ fontStyle: "italic", color: "white" }}
          target="_blank"
          rel="noopener noreferrer"
          href="https://mail.google.com/mail/?view=cm&fs=1&to=ro.augusto@gmail.com"
        >
          {" "}
          ro.augusto@gmail.com
        </a>

        <div>
          Usamos cookies e tecnologias semelhantes de acordo com a nossa{" "}
          <a href="/policy" >Política de Privacidade</a>. Ao continuar
          navegando, você concorda com essas condições.
        </div>
      </div>
    </div>
  );
};

export default Footer;
